//index.js
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    help_no: null,
    debug: false,
    isLogin: false,
    isLogout: true,
    user: null,
    avatar: null,
    market: 'ANY',
    market_cap: 100,
    sector: ['전체'],
    description: null,
    rules:[],    
    filters:[],
    stock_index: null,
    profit_index: null,
    sd_index: null,
    value_index: null,
    stable_index: null,
    dividend_index: null,
    val_index: null,
    fscore_index: null,
    growth_index: null,
    quant_id: null,
    title: null,
    amount: null,
    stocks: null,
    time: null,
    term: null,
    route: null,
    port_id: null,
    port_codes: [],
    port_names: [],
    test_term: [],
    etf_info: {},
    etfs: [],
    ports: [],
    who: null,
    tab: null,
    model_type: null,
    qs_country: null,
    qs_month: null,
    qs_data: null,
    qs_benchmark: null,
  },
  
  mutations: {
    setDebug(state, value) {
      state.debug = value['debug'];
    },

    setTab(state, value) {
      state.tab = value['tab'];
    },

    setMarket(state, value) {
      state.market = value['market'];
      state.market_cap = value['market_cap'];
      state.sector = value['sector'];
      state.description = value['description'];
    },
    
    setIndex(state, value) {
      state.stock_index = value['stock_index'];
      state.profit_index = value['profit_index'];
      state.sd_index = value['sd_index'];
      state.value_index = value['value_index'];
      state.stable_index = value['stable_index'];
      state.dividend_index = value['dividend_index'];
      state.val_index = value['val_index'];
      state.fscore_index = value['fscore_index'];
      state.growth_index = value['growth_index'];
    },

    setRule(state, value) {
      state.rules = value['rules'];
    },

    setFilter(state, value) {
      state.filters = value['filters'];
    },
    
    setTitle(state, value) {
      state.title = value['title'];
    },

    setTest(state, value) {
        state.title = value['title'];
        state.quant_id = value['quant_id'];
        state.amount = value['amount'];
        state.stocks = value['stocks'];
        state.time = value['time'];
        state.term = value['term'];
    },

    setRoute(state, value) {
        state.route = value['route'];
    },

    setPort(state, value) {
        state.port_id = value['port_id'];
        state.port_codes = value['port_codes'];
        state.port_names = value['port_names'];
    },

    setPortCodeName(state, value) {
      state.port_codes = value['port_codes'];
      state.port_names = value['port_names'];
  },

    setPortID(state, value) {
      state.port_id = value['port_id'];
  },

    setUser(state, value) {
        state.user = value['user'];
        state.avatar = value['avatar'];
    },

    setLogin(state, value) {
        state.isLogin = value['isLogin'];
    },

    setLogout(state, value) {
        state.isLogout = value['isLogout'];
    },

    setTestTerm(state, value) {
      state.test_term = value['test_term'];
    },

    setEtfInfo(state, value) {
      state.etf_info = value['etf_info'];
    },

    setEtfs(state, value) {
      state.etfs = value['etfs'];
    },

    setEtfTest(state, value) {
      state.title = value['title'];
      state.who = value['who'];
      state.model_type = value['model_type'];
      state.ports = value['ports'];      
      state.amount = value['amount'];
      state.term = value['term'];
    },

    setHelpNo(state, value) {
      state.help_no = value['help_no'];
    },

    setQS(state, value) {
      state.qs_country = value['qs_country'];
      state.qs_month = value['qs_month'];
      state.qs_data = value['qs_data'];
      state.qs_benchmark = value['qs_benchmark'];
    },

  },
  actions: {},
  getters: {}
})
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import store from "./store"

Vue.config.productionTip = false
// EventBus 생성
Vue.prototype.$EventBus = new Vue(); 

if (process.env.NODE_ENV == 'production') axios.defaults.baseURL = 'https://apiquant.tonyinvest.trade'

new Vue({
  router,
  axios,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

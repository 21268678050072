import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [  
  {
    path: '/',
    name: 'HelloTony',
    component: () => import('../views/HelloTony.vue')
    // component: () => import('../views/Test1.vue')
  },  
  // {
  //   path: '/stock-dashboard', 
  //   name: 'StockDashboard',
  //   component: () => import('../views/StockDashboard.vue')
  // },
  {
    path: '/filter',
    name: 'QuantFilterV02',
    component: () => import('../views/QuantFilterV02.vue')
  },
  {
    path: '/search',
    name: 'QuantSearchV02',
    component: () => import('../views/QuantSearchV02.vue')
  },
  {
    path: '/myquantrule/:type/:id',
    name: 'MyQuantRule',
    component: () => import('../components/MyQuantRule.vue'),
  },
  {
    path: '/backtest',
    name: 'Backtest',
    component: () => import('../views/Backtest.vue'),
  },
  {
    path: '/backtest_detail',
    name: 'BacktestDetail',
    component: () => import('../components/BacktestDetail.vue'),
    props: true,
  },
  {
    path: '/domestic/port',
    name: 'DomesticQuantPort',
    component: () => import('../views/QuantPort.vue'),
    props: true,
  },
  {
    path: '/global/port',
    name: 'GlobalQuantPort',
    component: () => import('../views/QuantPort.vue'),
    props: true,
  },
  {
    path: '/rule',
    name: 'QuantRule',
    component: () => import('../views/QuantRule.vue'),
    props: true,
  },
  {
    path: '/grid',
    name: 'GridTest',
    component: () => import('../views/GridTest.vue'),
    props: true,
  },
  {
    path: '/power',
    name: 'Power',
    component: () => import('../views/Power.vue'),
    props: true,
  },
  {
    path: '/power/account',
    name: 'PowerAccount',
    component: () => import('../views/PowerAccount.vue'),
    props: true,
  },
  {
    path: '/assets',
    name: 'Assets',
    component: () => import('../views/Assets.vue'),
    props: true,
  },
  {
    path: '/etf_port',
    name: 'EtfPort',
    component: () => import('../views/EtfPort.vue'),
    props: true,
  },
  {
    path: '/etf_backtest',
    name: 'EtfBacktest',
    component: () => import('../views/EtfBacktest.vue'),
    props: true,
  },
  {
    path: '/etf_backtest_result',
    name: 'EtfBacktestResult',
    component: () => import('../views/EtfBacktestResult.vue'),
    props: true,
  },
  {
    path: '/show_qs',
    name: 'QuantStats',
    component: () => import('../views/QuantStats.vue'),
    props: true,
  },
  {
    path: '/economy',
    name: 'EconomicIndex',
    component: () => import('../views/EconomicIndex.vue'),
    props: true,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    props: true,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    props: true,
  },
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router

<template>
<!-- App.vue -->

<v-app>   
  <v-app-bar
    app
    color="deep-purple"    
    dark
    clipped-left
  >
    <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
    <v-toolbar-title>Tony Quant</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="isLogout" to="/login" text color="white"> 로그인 </v-btn>        
    <v-btn v-if="isLogin" to="/logout" text color="white"> 로그아웃 </v-btn>        
    <v-btn v-if="isLogout" to="/signup" text color="white"> 회원가입 </v-btn>
    <v-avatar v-if="isLogin" color="white"> 
      <span class="purple--text text-h6">{{ avatar }}</span>
    </v-avatar>
  </v-app-bar>
    
  <v-navigation-drawer 
    app
    class="grey lighten-4"
    v-model="drawer"
    temporary
    clipped
  >
    <v-list      
      nav
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 orange--text font-weight-black"> Tony Quant </v-list-item-title>  
          <v-list-item-subtitle>(Beta Ver. 2023.1.10)</v-list-item-subtitle>   
          <v-list-item-subtitle v-if="isLogin" class="font-weight-bold">
            <br> {{ user }}님 환영합니다.
          </v-list-item-subtitle>          
        </v-list-item-content>        
      </v-list-item>              
      <!-- <v-list-item>
        <v-list-item-content>
          <v-row justify="center">
            <v-btn v-if="isLogout" to="/login" text color="primary"> 로그인 </v-btn>        
            <v-btn v-if="isLogin" to="/logout" text color="primary"> 로그아웃 </v-btn>        
            <v-btn v-if="isLogout" to="/signup" text color="primary"> 회원가입 </v-btn>
          </v-row>
        </v-list-item-content>
      </v-list-item> -->
    <v-divider></v-divider>

    <v-list-item to="/">        
      <v-list-item-icon>
      <v-icon>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Home</v-list-item-title>    
    </v-list-item>

    <v-list-group
      prepend-icon="mdi-earth"
    >
      <template v-slot:activator>
        <v-list-item-title>글로벌 경제</v-list-item-title>
      </template>

      <v-list-item @click="checkLogin('/economy')">        
        <v-list-item-title>글로벌 경제지표</v-list-item-title>
        <v-list-item-icon>
        <v-icon>mdi-arrow-top-right</v-icon>
        </v-list-item-icon>
      </v-list-item>      
    </v-list-group>

    <v-divider></v-divider>
    <v-list-group
      prepend-icon="mdi-chart-box"
    >
      <template v-slot:activator>
        <v-list-item-title>국내 주식</v-list-item-title>
      </template>

      <!-- <v-list-item to="/stock-dashboard" v-if="isLogin">        
          <v-list-item-title>나의 자산</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-clipboard-text</v-icon>
          </v-list-item-icon>
      </v-list-item> -->
      <v-list-item @click="checkLogin('/filter')">        
          <v-list-item-title>백테스트</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-magnify-expand</v-icon>
          </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="checkLogin('/rule')">        
          <v-list-item-title>퀀트전략</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-finance</v-icon>
          </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="checkLogin('/domestic/port')">        
          <v-list-item-title>나의 포토폴리오</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="checkLogin('/power')">        
          <v-list-item-title>세력주 검색</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-arrow-top-right</v-icon>
          </v-list-item-icon>
      </v-list-item>
      
      
      <!-- <v-list-item  @click="checkLogin('/test')">        
          <v-list-item-title>Test</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
      </v-list-item> -->
     
    </v-list-group>

    <v-divider></v-divider>
    <v-list-group
      prepend-icon="mdi-earth"
    >
      <template v-slot:activator>
        <v-list-item-title>글로벌 ETF</v-list-item-title>
      </template>

      <v-list-item @click="checkLogin('/assets')">        
          <v-list-item-title>자산 검색</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-playlist-check</v-icon>
          </v-list-item-icon>
      </v-list-item>

      <v-list-item @click="checkLogin('/etf_backtest')">        
          <v-list-item-title>백테스트</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-finance</v-icon>
          </v-list-item-icon>
      </v-list-item>

      <v-list-item @click="checkLogin('/etf_port')">        
          <v-list-item-title>자산배분 전략</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
      </v-list-item>     

      <v-list-item @click="checkLogin('/global/port')">        
          <v-list-item-title>나의 포토폴리오</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
      </v-list-item>    
    </v-list-group> 

    <v-list-group
      prepend-icon="mdi-cog"
      v-if="isManager"
    >
      <template v-slot:activator>
        <v-list-item-title>관리자 메뉴</v-list-item-title>
      </template>

      <v-list-item @click="checkLogin('/grid')">        
          <v-list-item-title>그리드 테스트</v-list-item-title>
          <v-list-item-icon>
          <v-icon>mdi-playlist-check</v-icon>
          </v-list-item-icon>
      </v-list-item>
    </v-list-group>
  </v-list>
  </v-navigation-drawer>

    
  <v-main>
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
  </v-main>
  <v-footer app>
      <v-col
      class="text-center"
      cols="12"
      >
      Copyright {{ new Date().getFullYear() }} — <strong>Anthony</strong>
      </v-col>
  </v-footer>

  <!-- 알림 메세지 -->
  <v-snackbar
  v-model="snackbar"
  :vertical="vertical"
  :color="snackColor"          
  >
    {{ snackText }}
    <template v-slot:action="{ attrs }">
        <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
        >
        Close
        </v-btn>
    </template>
  </v-snackbar>

</v-app>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: 'App',
  data: function () {
    return {
      drawer: false,
      group: null,
      mini: true,
      snackbar: false,
      vertical: true,
      snackColor: null,
      snackText: null,
    }
  },

  computed: {
    ...mapState(['debug', 'user', 'avatar', 'isLogin', 'isLogout']),
    
    isManager() {
      return this.isLogin && this.user == 'koas'? true : false;
    }
  },  

  mounted() {
    this.setDebug({'debug': false});
  },
  
  methods: {
    ...mapMutations(['setDebug']),

    checkLogin(to) {
      if (to == '/' && this.isLogin) this.$router.push(to);
      else if (to == '/economy' && this.isLogin) this.$router.push(to);
      else if (to == '/filter' && this.isLogin) this.$router.push(to);
      else if (to == '/rule' && this.isLogin) this.$router.push(to);
      else if (to == '/domestic/port' && this.isLogin) this.$router.push(to);
      else if (to == '/global/port' && this.isLogin) this.$router.push(to);
      else if (to == '/power' && this.isLogin) this.$router.push(to);
      else if (to == '/assets' && this.isLogin) this.$router.push(to);
      else if (to == '/etf_backtest' && this.isLogin) this.$router.push(to);
      else if (to == '/etf_port' && this.isLogin) this.$router.push(to);
      else if (to == '/grid' && this.isLogin) this.$router.push(to);
      else {
        this.snackbar = true;
        this.snackColor = 'warning';
        this.snackText = '로그인 또는 회원 가입하시기 바랍니다.';          
      }
    }
  },

}
</script>

<style>
  #app {
    font-family: 'Material Icons', 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: small;
  }

  /* each row block css in mobile*/
  .v-data-table>.v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(odd) {
    border-left: 6px solid deeppink;  
  }
  .v-data-table>.v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(even) {
    border-left: 6px solid blue; 
  }

  /* each cell border in mobile */
  .v-data-table>.v-data-table__wrapper .v-data-table__mobile-row {
    border-bottom: 1px solid #ededed;
  }
</style>